import {
  FormControl,
  FormLabel,
  Image,
  Select,
  Stack,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import React, { useEffect, useState } from 'react'
import { CtaBlock } from 'starterComponents'
import { Container, ResultsTable } from 'starterUiComponents'

const FreewheelPage = (props) => {
  const {
    data: {
      freewheel,
      page: {
        flexLayouts: { flexibleLayouts },
      },
    },
  } = props

  const getMakeSelectors = React.useCallback(() => {
    return uniq(freewheel.nodes.map((item) => item.make))
  }, [freewheel?.nodes])

  const getTransSelectors = React.useCallback(
    (make) =>
      uniq(
        freewheel.nodes
          .filter((item) => make && item.make.includes(make))
          .map((item) => item.transmission)
      ),
    [freewheel?.nodes]
  )

  const getResults = React.useCallback(
    (make, trans) => {
      return uniqBy(
        freewheel.nodes
          .filter(
            (item) =>
              item.make.includes(make) && item.transmission.includes(trans)
          )
          .map((item) => {
            return { pix: item.pix, desc: item.desc }
          }),
        'pix'
      )
    },
    [freewheel?.nodes]
  )

  const [selectors, setSelectors] = useState({
    makes: [],
    transmissions: [],
  })
  const [selections, setSelections] = useState({
    make: undefined,
    trans: undefined,
  })
  const [results, setResults] = useState([])

  const { make, trans } = selections
  const { makes, transmissions } = selectors

  useEffect(() => {
    setSelectors((selectors) => ({
      ...selectors,
      makes: getMakeSelectors(),
      transmissions: getTransSelectors(make),
    }))
    setResults(getResults(make, trans))
  }, [make, trans, getMakeSelectors, getTransSelectors, getResults])

  const onSelectChange = (e) => {
    setSelections({
      ...selections,
      [e.target.name]: e.target.value,
    })
  }
  const noResults =
    make === undefined || trans === undefined
      ? 'Please select the fields above to search for results.'
      : 'No information is available for your selection(s), please try your search again with another selection.'

  return (
    <>
      <Container mb={20}>
        <Text className="font-bold font-heading text-l">
          Enter Vehicle Information:
        </Text>
        <form>
          <div className="p-8 mb-5 bg-darkBlue">
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={5}
              w={{ base: '100%', lg: '50%' }}
              sx={{
                label: {
                  color: 'white',
                  fontStyle: 'italic',
                  mb: 2,
                  fontSize: 'base',
                },
                '.chakra-stack': {
                  width: '100%',
                  // alignItems: 'flex-start',
                },
              }}
            >
              <VStack>
                <FormControl id="make">
                  <FormLabel>Make</FormLabel>
                  <Select
                    placeholder="Make"
                    name="make"
                    layerStyle="input"
                    onChange={(e) => onSelectChange(e)}
                  >
                    {makes?.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
              <VStack>
                <FormControl id="trans">
                  <FormLabel>Transmission Type:</FormLabel>
                  <Select
                    placeholder="Transmission Type"
                    name="trans"
                    layerStyle="input"
                    onChange={(e) => onSelectChange(e)}
                  >
                    {make &&
                      transmissions?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </VStack>
            </Stack>
          </div>
        </form>

        {/* Results */}

        <div className="mb-4 italic font-normal text-lightGrey font-heading">
          Click on the results to view the instructional diagrams.
        </div>

        <ResultsTable
          headers={
            <>
              <Th w={{ base: '30%', md: '180px' }}>Diagram</Th>
              <Th w={{ base: '30%', md: '400px' }}>Description</Th>
            </>
          }
        >
          {results.length > 0 ? (
            results.map((item, i) => {
              const { pix, desc } = item
              const diagramLink = `https://atsgstaff.com/freewheel/pix/${pix}.png`
              const diagramImgUrl = `/pix/${pix}_small.png`
              return (
                <Tr
                  key={i}
                  className="flex items-center justify-start"
                  sx={{ '&:first-of-type': { pt: 5 } }}
                >
                  <Td
                    w={{ base: '30%', md: '180px' }}
                    className="text-sm uppercase"
                  >
                    <a href={diagramLink} target="_blank" rel="noreferrer">
                      <Image
                        src={diagramImgUrl}
                        alt={desc}
                        width={119}
                        height={112}
                      />
                    </a>
                  </Td>
                  <Td w={{ base: '30%', md: '400px' }} fontSize="sm">
                    {desc}
                  </Td>
                </Tr>
              )
            })
          ) : (
            <div className="mt-5">{noResults}</div>
          )}
        </ResultsTable>
      </Container>
      {flexibleLayouts?.map((block, index) => {
        switch (block.__typename) {
          case 'WpPage_Flexlayouts_FlexibleLayouts_CtaBlock':
            return <CtaBlock {...block} key={index} />

          default:
            return ''
        }
      })}
    </>
  )
}

export default FreewheelPage

export const freewheelQuery = graphql`
  query WpPageFreeWheelQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          __typename
          ...ctaBlockFragment
        }
      }
    }
    freewheel: allMysqlFreewheel {
      nodes {
        make
        transmission
        pix
        Id
        desc: clutch
      }
    }
  }
`
